import { graphql } from "../../../common/gql";


export const GET_ACTIVE_ORDER_AND_CUSTOMER = graphql(`
    query GetActiveOrderAndCustomer {
        activeOrder {
            ...Cart
        }
        activeCustomer {
            ...ActiveCustomer
        }
        activeUserWishlists {
            id
            name
            items {
                id
                productVariantId
            }
        }
        defaultShippingLocation {
            countryCode
            countryName
            isOutsideEU
        }
    }
`);
